import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Marienburg: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Marienburg,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Marienburg"
          d="M621.4,798.7c-11,13.5-31.8,42.7-41.4,57.3l-24,10v-4l-0.6,0.2L550,828l-18-18v-18l10-2l16,2 c0.3-0.1,40-24,40.2-24.1c2.1,3.8,5.8,10.9,10,16.9C611.8,790.1,616.3,794.8,621.4,798.7L621.4,798.7z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 542.6495 809.5262)"
        >
          Marienburg
        </text>
      </g>
    </>
  );
});
